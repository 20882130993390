<template>
  <v-card class="mx-auto sideCard" max-width="72" width="72" tile>
    <v-list class="sidebar py-0" style="width:72px !important">
      <div class="d-flex justify-center align-center" style="height: 56px">
        <v-img max-width="40" width="35" src='../assets/Logo.svg'></v-img>
      </div>
     <div class="h-divider"></div>
      <v-list-item-group mandatory  v-model="selectedItem" color="primary" style="width:72px !important;">
        <v-list-item style="width: 72px !important;"
          v-for="(item, i) in menuList"
          :key="i"
          @click="changeRoute(item)"
        >
          <v-list-item-icon>
            <img :src="getImgUrl(item.svg)" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    selectedItem: 0,
    menuList: [
      { text: "Dashboard", svg: "home" },
      { text: "Petition", svg: "Orders" },
      { text: "Projects", svg: "Positions" },
      { text: "CivilWorks", svg: "Holdings" },
      { text: "FollowUps", svg: "Funds" },
      { text: "Others", svg: "AppsIcon" },
      { text: "Contacts", svg:"contact"},
      { text: "Settings", svg: "Settings" },
    ],
  }),

  methods: {
    getImgUrl(img) {
      var images = require.context("../assets/menu/", false, /\.svg$/);
      return images("./" + img + ".svg");
    },

    changeRoute(val){
      this.$router.push("/" + val.text).catch(() => {});
    },

  },
  mounted(){
    this.selectedItem = this.menuList.findIndex(std=> std.text === this.$router.currentRoute.path.replace('/',''));
    console.log(this.text)
  }
  
};
</script> 

<style>
.sidebar .v-list-item .v-list-item--link .theme--light {
  display: block !important;
}
.sidebar .v-list-item__icon {
  margin: 0px !important;
}
.sidebar .v-list-item {
  text-align: center !important;
  padding: 8px 0px 0px 0px !important;
  display: block !important;
  height: 56px !important;
}
.sideCard {
  height: 100vh !important;
}
.sidebar .v-list-item__title {
  line-height: 16px !important;
  font-size: 11px !important;
}
.sidebar .v-list-item--link:before {
  top: 68px !important;
}
.sidebar .v-list {
  padding: 0px !important;
}
.sidebar {
  width: 64px !important;
}
.sidebar .v-list-item__content {
  padding: 0px !important;
}
.sidebar .v-list-item-group .v-list-item--active,
.v-list-item-group .v-list-item:hover {
filter: invert(44%) sepia(54%) saturate(739%) hue-rotate(172deg) brightness(94%) contrast(85%);
}
.theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
    opacity: 0;
}
.theme--light.v-divider {
    border-color: rgb(0 0 0 / 35%);
}
.h-divider{
    border-bottom: 1px solid #a2a2a2;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: -1px;
}
</style>